<template>
  <div class="text-left bg-white-alt">
    <b-alert :show="alert.dismissCountDown" fade id="alert"
             @dismiss-count-down="countDownChanged" class="alert-success">{{alert.notification}}
    </b-alert>
    <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
             @dismiss-count-down="countDownChangedDanger" class="alert-danger multi-line pre-formatted">
            {{ alertDanger.notification }}
        <div class="x dismiss-x" @click="() => {this.alertDanger.dismissCountDown = 0}"></div>
    </b-alert>
    <NavBarNew :greenLogo='true'/>
    <main>
        <section class="tr-container tr-glasses-login">
            <form action="" @submit.prevent="onSubmit" novalidate>
                <h1 class="tr__h1" style="max-width: 100%">{{ $t("public.resetPasswordPage.forgottenPassword") }}</h1>
                <div class="form-card d-flex">
                    <div v-if="successfullyChanged" class="tr-left-part tr-border-right tr-right-dashed-line w-100">
                      <div style="max-width: 560px; margin-inline: auto">
                        <h3 style="line-height: 25px; margin-bottom: 25px;">{{ $t("public.resetPasswordPage.passwordChangedSuccessfully") }}</h3>
                        <p>{{ $t("public.resetPasswordPage.forLoginClickOn") }} <router-link to="/login" style="color: #30E781">{{ $t("buttons.login") }}</router-link></p>
                      </div>
                    </div>
                    <div v-else-if="!$route.params.token" class="tr-left-part tr-border-right tr-right-dashed-line w-100">
                      <div style="max-width: 560px; margin: auto">
                        <h3 style="line-height: 25px; max-width: 560px; margin: 0 auto 30px">{{successfullyChanged ? $t("public.resetPasswordPage.passwordChangedSuccessfully") : $t("public.resetPasswordPage.fillInYourEmailAddress") }}</h3>
                        <TrekioInput dark-bg id="userLogin" trim v-model="form.userLogin" :label="$t('inputLabels.login')" placeholder=" " maxlength="100" required
                          dynamicLabel :error="error.userLogin"
                        />  
                      </div>
                    </div>
                    <div v-else class="tr-left-part tr-border-right tr-right-dashed-line w-100">
                        <h3 style="line-height: 25px; max-width: 560px; margin: 0 auto 30px">{{ $t("public.resetPasswordPage.fillInNewPassword") }}</h3>
                        <div class="d-flex flex-wrap mx-auto" style="gap: 40px; max-width: 560px">
                          <TrekioInput dark-bg width="260px" type="password" autocomplete="new-password" id="password" trim v-model="form.password" :label="$t('inputLabels.password')" placeholder=" " maxlength="100" required
                            dynamicLabel :error="error.password"
                          />  
                          <TrekioInput dark-bg width="260px" type="password" autocomplete="new-password" id="repeatPassword" trim v-model="form.repeatPassword" :label="$t('inputLabels.repeatPassword')" placeholder=" " maxlength="100" required
                            dynamicLabel :error="error.repeatPassword"
                          />  
                        </div>
                    </div>
                    
                    <div class="tr-right-part tr-border-left d-flex flex-column jusify-content-center" :class="{empty: successfullyChanged}">
                      <div v-if="!successfullyChanged" class="w-100 d-flex flex-column align-items-center">
                        <TrekioButton type="submit" primary :isLoading="loading != ''" :loadingText="loading" class="login-button" style="width: 260px; margin-bottom: 20px">{{$route.params.token ? $t("public.resetPasswordPage.changePassword") : $t("buttons.send")}}</TrekioButton>
                        <div v-if=!$route.params.token class="text-center">
                            <router-link to="/login" style="color: #30E781">{{ $t("buttons.login") }}</router-link>
                        </div>
                      </div>
                    </div>
                </div>
            </form>
        </section>
    </main>
    <Footer style="margin-top: 60px"/>
  </div>
</template>

<script>
    import Footer from "@/components/Footer";
    import UserService from "@/services/UserService"
    import sharedUtils from "../utils/sharedUtils";

    export default {
        name: "LoginPage",

        components: {
            Footer,
        },
        
        data: function() {
            return {
                form: {
                    userLogin: '',
                    password: '',
                    repeatPassword: ''
                },
                error: {
                  userLogin: '',
                  password: '',
                  repeatPassword: ''
                },
                  alert: {
                    dismissSecs: 10,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                  alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                loading: '',
                successfullyChanged: false,
                showPassword: {
                  password: false,
                  repeatPassword: false
                }
              }
            },
        created() {
            this.resetForm();
        },

        methods: {
          changePasswordVisibility(inputId) {
                this.showPassword[inputId] = !this.showPassword[inputId] 
            },
          validateInputs() {
                let errorCount = 0
                this.error.userLogin = ''
                this.error.password = ''
                this.error.repeatPassword = ''
                
                const inputs = document.querySelectorAll("input")
                inputs.forEach(input => {
                    if (input.required && !input.value) {
                      this.error[input.id] = this.$t("inputErrors.required")
                        errorCount += 1
                    }
                })
                if (this.form.password) {
                    if (!sharedUtils.passwordHasRequiredSymbols(this.form.password)) {
                      errorCount += 1
                      this.error.password = this.$t("inputErrors.incorrectPasswordFormat")
                    }
                }
                if (this.form.repeatPassword) {
                    if (this.form.repeatPassword !== this.form.password) {
                      errorCount +=1
                      this.error.repeatPassword = this.$t("inputErrors.passwordsDoNotMatch")
                    }
                }
                errorCount > 0 && document.querySelector("h1").scrollIntoView({behavior: 'smooth', block: 'nearest'})
                return errorCount > 0 ? false : true
          },
            async onSubmit() {
              this.alertDanger.dismissCountDown = this.alert.dismissCountDowny = 0
              console.log("OnSubmit")
              if (!this.validateInputs()) return
                if (this.$route.params.token) {
                  this.loading = this.$t("loading.loading")
                  await UserService.resetPasswordByToken(this.$route.params.token, this.form.password)
                  .then((resp) => {
                    this.successfullyChanged = true
                  })
                  .catch(err => {
                    this.showAlertDanger(this.$t('alerts.passwordResetLinkExpiredError'))
                  })
                  this.loading = ''
                }
                else {
                  this.loading = this.$t("loading.send")
                  const retoken = await this.recaptcha()
                  await UserService.requestPasswordReset(this.form.userLogin, retoken)
                    .then(() => this.showAlert(this.$t('alerts.passwordResetLinkSent')))
                    .catch(err => {
                      console.log(err)
                      this.showAlertDanger(this.$t('alerts.loginDoesNotExistError', [this.form.userLogin]))
                    })
                }
                this.loading = ''
          },

          async recaptcha() {
            const token = await this.$recaptchaToken('PASSWORD_RESET')
            return token
          },

          showAlert: function(notification) {
              this.alert.dismissCountDown = this.alert.dismissSecs;
              this.alert.notification = notification;
          },
          countDownChanged: function(dismissCountDown) {
              this.alert.dismissCountDown = dismissCountDown;
          },
          showAlertDanger: function(notification) {
              this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs;
              this.alertDanger.notification = notification;
          },
          countDownChangedDanger: function(dismissCountDown) {
              this.alertDanger.dismissCountDown = dismissCountDown;
          },

          resetForm: function() {
              this.form = {
                  userName:'',
                  password: '',
              }
          },
        }

    }
</script>

<style lang="scss" scoped>

#password, #repeatPassword {
    padding-right: 55px;
 }
.show-password {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 18px;
}

.tr-right-part.empty {
  min-height: 130px;
  min-width: 340px;

  @media (max-width: 1100px) {
    min-width: 100%;
  }
}

.form-card {
  height: 270px
}

</style>
